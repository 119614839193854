import React, {useEffect, useMemo, useState} from "react";
import "../style/Main.scss";
import Header from "./Header";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Footer from "./Footer";
import useWindowDimensions from "../util/Utils";
import Modal from "react-modal";
import p1 from "../assets/images/temp/p1.png";
import p2 from "../assets/images/temp/p2.png";
import p3 from "../assets/images/temp/p3.png";
import p4 from "../assets/images/temp/p4.png";
import p5 from "../assets/images/temp/p5.png";
import p6 from "../assets/images/temp/p6.png";
import p7 from "../assets/images/temp/p7.png";
import modalImg from "../assets/images/modalImg.jpg";
import closeImg from "../assets/images/close_icon.png";
function Main(props) {

  const [isModalShow, setIsModalShow] = useState(false);

  useEffect(() => {
    setIsModalShow(true);
  }, []);

  return (
      <>
        {
          isModalShow ?
              <div className={'img-modal'}>
                <div className={"img-modal-body-container"}>
                  <img className="close-btn" onClick={()=>setIsModalShow(false)} src={closeImg}/>
                  <img className='modal-img' src={modalImg} alt={''}/>
                </div>
              </div>
              :
              <div id={"Main"}>
                <div className="content-wrapper">
                  <Header />
                  <img className={'content-img'} alt={''} src={p1}/>
                  <img className={'content-img'} alt={''} src={p2}/>
                  <img className={'content-img'} alt={''} src={p3}/>
                  <img className={'content-img'} alt={''} src={p4}/>
                  <img className={'content-img'} alt={''} src={p5}/>
                  <img className={'content-img'} alt={''} src={p6}/>
                  <img className={'content-img'} alt={''} src={p7}/>
                  {/*<Section1 />*/}
                  {/*<Section2 />*/}
                  {/*<Section3 />*/}
                  {/*<Section4 />*/}
                  {/*<Section5 />*/}
                </div>
                {/*<Footer />*/}
              </div>
        }
      </>

  );
}

export default Main;
