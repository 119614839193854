import React, {useMemo} from "react";
import "../style/Header.scss";
import logoBlack from "../assets/images/Logo_Black.png";
import headerImg from "../assets/images/pc/00_Hero.png";
import headerImgMobile from "../assets/images/mobile/00_Hero.png";

import useWindowDimensions from "../util/Utils";

function Header(props) {
  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => {
    return width <= 780;
  }, [width]);

  const onClickNav = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div id={"Header"}>
      <div className="logo-wrapper">
        <img src={logoBlack} alt="" className="logo-img" />
      </div>
      {/*<div className="header-content-wrapper">*/}
      {/*  <div className="text-img-wrapper">*/}
      {/*    <img src={isMobile ? headerImgMobile : headerImg} alt="" className="header-img" />*/}
      {/*  </div>*/}
      {/*  <div className="header-button-wrapper">*/}
      {/*    <div className="header-button">*/}
      {/*      <div onClick={() => onClickNav("Section1")} className="header-button-text">*/}
      {/*        TECH SPECS*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="header-button">*/}
      {/*      <div onClick={() => onClickNav("Section2")} className="header-button-text">*/}
      {/*        TOKENOMICS*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="header-button">*/}
      {/*      <div onClick={() => onClickNav("Section3")} className="header-button-text">*/}
      {/*        GO TO MARKET*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="header-button">*/}
      {/*      <div onClick={() => onClickNav("Section4")} className="header-button-text">*/}
      {/*        TIMELINE*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="header-button">*/}
      {/*      <div onClick={() => onClickNav("Section5")} className="header-button-text">*/}
      {/*        TEAM*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="header-button-red">Comming Soon</div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}

export default Header;
